// src/pages/ChatSession.js
import React from 'react';
import { useParams } from 'react-router-dom';

const ChatSession = () => {
  const { id } = useParams(); // Get the chatbot ID from the URL

  return (
    <div className="chat-session">
      <h2>Chat Session with Bot ID: {id}</h2>
      {/* Here you would integrate the chat UI */}
    </div>
  );
};

export default ChatSession;
