// src/components/FooterSection.js
import React from 'react';

export const FooterSection = () => {
  return (
    <footer id="footer" className="container py-24 sm:py-32 mx-auto px-8 text-gray-800">

      <div className="p-10 bg-card border border-secondary rounded-2xl">
        <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-x-12 gap-y-8">
          <div className="col-span-full xl:col-span-2">
            <a href="#" className="flex items-center">
            <img src='/images/logo_transp.png' alt="Logo" className="w-12 h-12" />
            <span className="text-3xl font-bold text-gray-700  -ml-2">pressive AI</span> {/* Adjust the margin as needed */}  
            </a>
            <p className="text-base text-gray-600">
              For inquiries, email <a href="mailto:customer@xpressive.ai" className="text-blue-600 hover:underline">customer@xpressive.ai</a>
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-lg text-gray-600">Contact</h3>
            <a href="https://www.linkedin.com/company/xpressiveai" className="text-gray-600 hover:text-gray-900">
              LinkedIn
            </a>
            <a href="https://twitter.com/xpressiveai" className="text-gray-600 hover:text-gray-900">
              Twitter
            </a>
          </div>

         <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-lg text-gray-600">Platforms</h3>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              Web
            </a>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-lg text-gray-600">Privacy</h3>
            <a href="/privacy" className="text-gray-600 hover:text-gray-900">
              Privacy Policy
            </a>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-lg text-gray-600">Community</h3>
            <a href="https://join.slack.com/t/xpressiveai/shared_invite/zt-2gma5ny4k-VDIDtgQU4B6F2ou0~~qBkw" className="text-gray-600 hover:text-gray-900">
              Slack
            </a>
          </div>
        </div>

        <div className="my-6 border-t border-secondary"></div>
        <section className="text-center">
          <h3 className="text-gray-600 text-sm">&copy; 2024 Xpressive AI. All rights reserved.</h3>
        </section>
      </div>
    </footer>
  );
};

export default FooterSection;
