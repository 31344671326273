// src/components/ChatbotCard.js
import React from 'react';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';

const ChatbotCard = ({ name, numberOfChats, imageUrl, description, url, price, size = "default" }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="block cursor-pointer"
    >
      <div
        className={`bg-gray-50 shadow-lg ${
          size === "small" ? "w-48 h-24 p-2 rounded-lg" : "w-80 h-32 p-4 rounded-xl"
        } m-1 flex items-center space-x-2 transition-transform transform hover:scale-105 flex-shrink-0 relative`}
      >
        <img
          src={imageUrl}
          alt={name}
          className={`${
            size === "small" ? "w-12 h-12" : "w-20 h-20"
          } max-w-full max-h-full rounded-lg object-contain shadow-md`}
        />
        <div className="flex-1">
          <h2 className={`font-bold text-gray-700 mb-1 ${size === "small" ? "text-xs" : "text-sm"}`}>
            {name}
          </h2>
          {/* Hide description in small view for compact design */}
          <p className="text-xs text-gray-500 mb-2">
            {description}
          </p>
          {size !== "small" && (
            <p className="flex items-center text-sm text-gray-600">
              <ChatBubbleLeftIcon className={`mr-1 ${size === "small" ? "h-4 w-4" : "h-5 w-5"} text-blue-400`} />
              {numberOfChats} sessions
            </p>
          )}
        </div>
        {/* Conditionally render price in the bottom-right corner */}
        {price && (
          <span className="absolute bottom-2 right-2 text-xs font-semibold text-green-600 bg-white rounded px-2 py-1 shadow-sm">
            {price}
          </span>
        )}
      </div>
    </a>
  );
};





export default ChatbotCard;
