// ChatbotList.js
import React from 'react';
import ChatbotCard from '../components/ChatbotCard';

const ChatbotList = () => {
  const handleChatStart = (id) => {
    console.log(`Start chat with bot id: ${id}`);
  };

  const chatbotCategories = {

    
    EarlyStageFounder: [
      { id: 2, name: 'Mock pitch to an Angel Investor', numberOfChats: 3, imageUrl: '/images/avatar2.png', description: 'Pitch to an angel investor and receive detailed insights. Review your recording afterward.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=pitch-to-angel' },
      { id: 3, name: 'Mock sales pitch to an enterprise customer', numberOfChats: 2, imageUrl: '/images/avatar1.png', description: 'Simulate a sales scenario with an enterprise customer.', url: 'https://calendly.com/xpressiveai/20-mins-interaction?a2=sales-pitch-to-enterprise' },
      { id: 4, name: 'Legal Basics', numberOfChats: 3, imageUrl: '/images/avatar2.png', description: 'Understand essential legal requirements for your startup.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=legal-basics' },
      { id: 5, name: 'Review your launch strategy', numberOfChats: 3, imageUrl: '/images/avatar2.png', description: 'Review your launch strategy.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=launch-strategy-review' },
      { id: 6, name: 'Equity & Cap Table', numberOfChats: 2, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Carta-logo.svg/220px-Carta-logo.svg.png', description: 'Learn the basics of structuring equity and managing your cap table.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=equity-cap-table' },
      { id: 7, name: 'Co-Founder Search', numberOfChats: 2, imageUrl: 'https://media.licdn.com/dms/image/v2/D560BAQG682cxSRYzug/company-logo_200_200/company-logo_200_200/0/1708487318725/coffeespace_inc_logo?e=2147483647&v=beta&t=Vmfi6h9K5ucyaoacW78dIWb6c8G3BzZj8vH2mjGh8oE', description: 'Learn how to find and assess potential co-founders.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=co-founder-search' },
      { id: 8, name: 'Getting Your First 100 Users', numberOfChats: 3, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Y_Combinator_logo.svg/1200px-Y_Combinator_logo.svg.png', description: 'Refine your user acquisition strategy based on YC’s articles.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=first-100-users' },
      { id: 9, name: 'Immigration Advice', numberOfChats: 1, imageUrl: '/images/avatar2.png', description: 'A non-legal advice bot based on articles by various immigration firms.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=immigration-advice' },
      { id: 10, name: 'Don’t Spend! Free Resources', numberOfChats: 5, imageUrl: '/images/avatar2.png', description: 'Have you taken advantage of all the free resources for startups?', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=free-resources' },
      { id: 11, name: 'Enterprise Sales', numberOfChats: 3, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Y_Combinator_logo.svg/1200px-Y_Combinator_logo.svg.png', description: 'Review your enterprise sales strategy based on YC’s articles.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=enterprise-sales' },
      { id: 12, name: 'Social Media Strategy', numberOfChats: 0, imageUrl: 'https://www.socialstatus.io/wp-content/uploads/2020/07/socialpilot-logo-sq.png', description: 'Learn social media tactics for early-stage growth.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=social-media-strategy' },
      { id: 13, name: 'Networking Skills', numberOfChats: 0, imageUrl: '/images/avatar2.png', description: 'Receive expert feedback on your networking approach.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=networking-skills' },
    ],
    
    SoftwareEngineer: [
      { id: 1, name: 'System Design Mock Interview', numberOfChats: 3, imageUrl: 'https://assets.algoexpert.io/static/images/ae-social-logo.png', description: 'Simulate a general system design interview.', url: 'https://calendly.com/xpressiveai/20-mins-interaction?a2=system-design-interview',  price: "$20"  },
      { id: 2, name: 'Behavioral Mock Interview', numberOfChats: 2, imageUrl: '/images/avatar1.png', description: 'Simulate a general behavioral interview.', url: 'https://calendly.com/xpressiveai/20-mins-interaction?a2=behavioral-interview',  price: "$20"  },
      { id: 3, name: 'Meta System Design Mock Interview', numberOfChats: 3, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Meta_Platforms_Inc._logo.svg', description: 'Prepare for a Meta system design interview.', url: 'https://calendly.com/xpressiveai/20-mins-interaction?a2=meta-system-design', price: "$20" },
      { id: 4, name: 'Amazon Behavioral Mock Interview', numberOfChats: 2, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg', description: 'Prepare for an Amazon behavioral interview.', url: 'https://calendly.com/xpressiveai/20-mins-interaction?a2=amazon-behavioral-interview', price: "$20" },
      { id: 5, name: 'Google System Design Mock Interview', numberOfChats: 2, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png', description: 'Google system design interview.', url: 'https://calendly.com/xpressiveai/20-mins-interaction?a2=google-system-design', price: "$20" },
      { id: 6, name: 'Amazon System Design Mock Interview', numberOfChats: 3, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg', description: 'Prepare for an Amazon system design interview.', url: 'https://calendly.com/xpressiveai/20-mins-interaction?a2=amazon-system-design', price: "$20" },
      { id: 7, name: 'I Need a Raise!', numberOfChats: 2, imageUrl: '/images/avatar1.png', description: 'Simulate a performance review discussion with your manager.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=performance-review' },
      { id: 7, name: 'Salary Negotiation Mock', numberOfChats: 2, imageUrl: '/images/avatar2.png', description: 'Practice salary negotiation in a mock discussion.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=salary-negotiation' },
      { id: 8, name: 'Productivity Tips!', numberOfChats: 4, imageUrl: '/images/avatar1.png', description: 'Discuss different strategies for improving productivity.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=productivity-tips' },
      { id: 9, name: 'LinkedIn Optimization', numberOfChats: 3, imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/LinkedIn_2021.svg/1920px-LinkedIn_2021.svg.png', description: 'Optimize your LinkedIn profile for job applications.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=linkedin-optimization' },
      { id: 10, name: 'Immigration Advice', numberOfChats: 3, imageUrl: '/images/avatar2.png', description: 'A non-legal advice bot based on articles by various immigration firms.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?immigration-advice' },
      { id: 11, name: 'Optimize Your Health!', numberOfChats: 2, imageUrl: '/images/avatar1.png', description: 'Explore strategies to optimize your health.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=health-optimisation' },
      { id: 12, name: 'AI Learning Plan', numberOfChats: 2, imageUrl: '/images/avatar1.png', description: 'Discuss a customized AI learning strategy.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=ai-learning-plan' },
      { id: 13, name: 'Negotiation 101', numberOfChats: 3, imageUrl: '/images/avatar1.png', description: 'Develop essential negotiation skills through mock scenarios.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=negotiation-skills' },
    ],

    JustConnect: [
      { id: 1, name: 'Stranger in a Cafe', numberOfChats: 3, imageUrl: '/images/avatar1.png', description: 'Just a general discussion with a stranger.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=stranger-conversation' },

      { id: 3, name: 'You are funny', numberOfChats: 4, imageUrl: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/badd596b-ac1c-472f-8cf1-6609b7e4d635/d1u3lrl-b0163b9a-4b66-4669-9196-24025fadae2e.jpg/v1/fill/w_1024,h_1025,q_75,strp/funny_dog_2_by_cathita_d1u3lrl-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTAyNSIsInBhdGgiOiJcL2ZcL2JhZGQ1OTZiLWFjMWMtNDcyZi04Y2YxLTY2MDliN2U0ZDYzNVwvZDF1M2xybC1iMDE2M2I5YS00YjY2LTQ2NjktOTE5Ni0yNDAyNWZhZGFlMmUuanBnIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.6vdmpXVixFtqI7Nl7lgEESNcvJ6mropksOuROYNF8xM', description: 'Embark on a funny adventure, role-play with a character', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=funny-chat' },

      { id: 11, name: 'Critical thinking', numberOfChats: 3, imageUrl: 'https://cdn.pixabay.com/photo/2024/04/19/22/25/man-8707406_1280.png', description: 'How to handle emotions and build critical thinking skills', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=critical-thinking' },

      { id: 12, name: 'Digital Minimalism', numberOfChats: 2, imageUrl: 'https://cdn.creazilla.com/digital-illustrations/1685643/minimalist-art-animal-illustration-xl.jpeg', description: 'Learn about digital minimalism and its benefits.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=digital-minimalism' },            
      
      { id: 8, name: 'Boring party', numberOfChats: 3, imageUrl: '/images/avatar2.png', description: 'Talk to someone at a boring party!', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=scroll-break' },
      
      { id: 9, name: 'Hello Neighbour!', numberOfChats: 4, imageUrl: 'https://royallepagetriland.com/wp-content/uploads/2022/09/which-london-neighbourhood-is-right-for-you-800x700.jpg', description: 'A friendly chat with a neighbour', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=time-travel-adventure' },
      
      { id: 10, name: 'Not so friendly', numberOfChats: 2, imageUrl: 'https://www.picpedia.org/handwriting/images/goals.jpg', description: 'Talk to a person who is not friendly.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=micro-goals' },
      { id: 4, name: 'Productive!', numberOfChats: 2, imageUrl: 'https://attic.sh/20wh8pi176m9fkgzj2odc2pzb11g', description: 'Learn small, effective changes to improve daily productivity and focus.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=daily-productivity' },

      { id: 2, name: 'Social Media Detox', numberOfChats: 3, imageUrl: '/images/avatar2.png', description: 'Explore the benefits of taking a break from social media and learn tips for a dopamine detox.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=social-media-detox' },
      { id: 7, name: 'Interrogation', numberOfChats: 3, imageUrl: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/beb997a5-1a16-4312-8813-f3f51aaef61b/ddz3eu1-362079ad-e912-4c86-95d5-5d94ea122d25.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2JlYjk5N2E1LTFhMTYtNDMxMi04ODEzLWYzZjUxYWFlZjYxYlwvZGR6M2V1MS0zNjIwNzlhZC1lOTEyLTRjODYtOTVkNS01ZDk0ZWExMjJkMjUuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.aD5KzLLdb6qmRe9WN0rWCppDpAbR1LomFyl259tRqsE', description: 'Take on the role of a detective and interogate a suspect, gather clues, and solve a mystery!', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=detective-roleplay' },

     
    ],

    Coaches: [
        { id: 2, name: 'Life Coach', numberOfChats: 3, imageUrl: '/images/avatar1.png', description: 'Personal growth and self-discovery.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=life-coach' },
        { id: 3, name: 'All of Huberman', numberOfChats: 2, imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmhstuHvfNchlyjsuP6AL-BF99ZDhCPJUXqA&s', description: 'Discuss content based on Huberman\'s podcast.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=huberman-content' },
        { id: 4, name: 'Public Speaking Trainer', numberOfChats: 1, imageUrl: '/images/avatar2.png', description: 'Develop effective speaking skills.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=public-speaking' },
        { id: 5, name: 'Startup Advisor', numberOfChats: 3, imageUrl: '/images/avatar2.png', description: 'Advice on startup growth and management.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=startup-advisor' },
        { id: 6, name: 'Relationship Coach', numberOfChats: 2, imageUrl: '/images/avatar1.png', description: 'Tips on building healthy relationships.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=relationship-coach' },
        { id: 7, name: 'Mindfulness Coach', numberOfChats: 4, imageUrl: '/images/avatar2.png', description: 'Techniques for mindfulness and relaxation.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=mindfulness-coach' },
        { id: 8, name: 'Financial Advisor', numberOfChats: 2, imageUrl: '/images/avatar1.png', description: 'Guidance on managing finances.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=financial-advisor' },
        { id: 9, name: 'Parenting Coach', numberOfChats: 4, imageUrl: '/images/avatar1.png', description: 'Support on effective parenting strategies.', url: 'https://calendly.com/xpressiveai/10-mins-interaction?a2=parenting-coach' },
      ]
    };
  
  return (
    <div className="container mx-auto px-8 font-sans ">
    {/* Top Block Section */}
      
            <div
  className="relative bg-cover bg-center text-white p-12 mb-8 rounded-lg animate-moveBackground"
  style={{
    backgroundImage: `url('/images/interaction.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    animation: 'moveBackground 15s ease-in-out infinite',
  }}
>



        <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-blue-500 opacity-75 rounded-lg"></div>
        <div className="relative z-10 text-center">
        <h1 className="text-4xl font-extrabold mb-4">Deep, nuanced learning—just a conversation away.</h1>

<p className="text-lg max-w-3xl mx-auto mb-8">
  Experience the new way of learning. Our AI-powered avatars bring you real-time, personalized advice tailored to your unique needs.
</p>


        </div>
    </div>

    {/* Chatbot List */}
    {Object.keys(chatbotCategories).map((category) => {
  const bots = chatbotCategories[category];
  const firstRowBots = bots.slice(0, 4); // First 4 bots (large)
  const remainingBots = bots.slice(4);   // Remaining bots (small)

  // Split remaining bots into chunks of 6 for the smaller rows
  const smallBotRows = [];
  for (let i = 0; i < remainingBots.length; i += 6) {
    smallBotRows.push(remainingBots.slice(i, i + 6));
  }

  return (
    <div key={category} className="mb-6">
      <h2 className="text-lg font-bold mt-2 text-gray-600">{category}</h2>
      
      {/* First Row - Large Cards */}
      <div className="flex overflow-x-auto space-x-4 scrollbar-hide scroll-smooth">
        {firstRowBots.map((bot) => (
          <ChatbotCard
            key={bot.id}
            name={bot.name}
            description={bot.description}
            numberOfChats={bot.numberOfChats}
            imageUrl={bot.imageUrl}
            url = {bot.url}
            price={bot.price} // Pass the price if it exists
            onChatStart={() => handleChatStart(bot.id)}
          />
        ))}
      </div>

      {/* Subsequent Rows - Small Cards in groups of 6 */}
      {smallBotRows.map((row, rowIndex) => (
        <div key={`small-row-${rowIndex}`} className="flex overflow-x-auto space-x-3 scrollbar-hide scroll-smooth mt-4">
          {row.map((bot) => (
            <ChatbotCard
              key={bot.id}
              name={bot.name}
              description={bot.description}
              numberOfChats={bot.numberOfChats}
              imageUrl={bot.imageUrl}
              url = {bot.url}
              price={bot.price} // Pass the price if it exists
              onChatStart={() => handleChatStart(bot.id)}
              size="small" // Smaller size for subsequent rows
            />
          ))}
        </div>
      ))}
    </div>
  );
})}



    </div>
  );
};

export default ChatbotList;

