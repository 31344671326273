// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatbotList from './pages/ChatbotList';
import ChatSession from './pages/ChatSession';
import Header from './components/Header'; // Import the header
import Footer from './components/Footer'; // Import the footer

function App() {
  return (
    <Router>
      <div>
        <Header /> {/* Add the header here */}
        <Routes>
          <Route path="/" element={<ChatbotList />} />
          <Route path="/chat/:id" element={<ChatSession />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
