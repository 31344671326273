// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
//import logo from '/nlogo.png';

const Header = () => {
    return (
      <header className="bg-white shadow-md py-4 px-8 flex justify-between items-center">
        <div className="text-2xl font-bold text-gray-700 flex items-center">
          <Link to="/" className="flex items-center">
            <img src='/images/logo_transp.png' alt="Logo" className="w-10 h-10" />
            <span className="-ml-2">pressive AI</span> {/* Adjust the margin as needed */}
          </Link>
        </div>
      </header>
    );
  };
  
/*const Header = () => {
  return (
    <header className="bg-white shadow-md py-4 px-8 flex justify-between items-center">
      <div className="text-2xl font-bold text-gray-700">
        <Link to="/">Xpressive AI</Link>
      </div>
    </header>
  );
};*/

export default Header;
